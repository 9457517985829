import type { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeVarsAPACLWNZ, buildThemeMixinsAPACLWNZ, buildThemeWidgetStylesAPACLWNZ } from 'dw-apac-lwnz/theme';

const createTheme: ThemeCreateFunction = () =>
  buildTheme(
    (vars: Pick<Theme, CommonThemeVars>) => merge({}, buildThemeVarsAPACLWNZ(vars)),
    (vars: Omit<Theme, 'mixins' | 'widgets'>) => buildThemeMixinsAPACLWNZ(vars),
    (vars: Omit<Theme, 'widgets'>) => {
      const buildThemeWidget = buildThemeWidgetStylesAPACLWNZ(vars);
      return merge({}, buildThemeWidget, {
        Footer: {
          navItems: `
            ${vars.mixins.footerNavItems}
            ul {
              margin: 12px 0 0 20px !important; 
              ${vars.breakpoints.lg} {
                margin: 12px 0 0 0px !important;
              }
              li {
                line-height: 35px;
              }
            }
          `,
        },
      });
    },
  );

export default createTheme;

