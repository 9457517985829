/**
 * Theme for Laithwaites AU-LWNZ site
 */
import type { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeMixinsAPACLW, buildThemeWidgetStylesAPACLW, buildThemeVarsAPACLW } from 'dw-apac-law/theme';

export const buildThemeVarsAPACLWNZ = (vars: Pick<Theme, CommonThemeVars>) => {
  const lwPink = '#E2004D';
  const lwPink500 = '#E2004D';
  const lwGrey700 = '#616161';
  const lwUnlimitedMemberPrice = '#009F8E';
  const lwJoinWhite = '#FFFFFF';
  const lwBodyBlack = '#222222';

  const colors = {
    primary1: lwPink,
    textPrimaryBg: lwPink500,
    textSecondaryBg: lwGrey700,
    textUnlimitedPrice: lwUnlimitedMemberPrice,
    lwJoinWhite,
    lwBodyBlack,
    savingsTextColor: lwPink500,
  };
  const fonts = {
    sizeH5: '18px',
  };

  const lwThemeVars = buildThemeVarsAPACLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'Laithwaites NZ',
    },
    icons,
    colors,
    fonts,
  });
};
export const buildThemeMixinsAPACLWNZ = (vars: Omit<Theme, 'mixins' | 'widgets'>) => buildThemeMixinsAPACLW(vars);
export const buildThemeWidgetStylesAPACLWNZ = (vars: Omit<Theme, 'widgets'>) => {
  const buildThemeWidget = buildThemeWidgetStylesAPACLW(vars);
  return merge({}, buildThemeWidget, {
    CmsTBSPage: {
      sideBarLegacy: `
        & .sidebar{
          & .grid-container{
             div{
              width:100%;
              .navigation-link{
                h4{
                  display:none; 
                }
                & a{
                  margin-left: 10px;
                  margin-bottom:12px;
                  display:flex;
                  align-items:center;
                  gap:10px;
                  font-family:${vars.fonts.familySecondary};
                  font-size: ${vars.fonts.fontSize14};
                  font-weight: ${vars.fonts.weightNormal};
                  line-height: 22.4px;
                  letter-spacing: 0.04em;
                   color: ${vars.colors.grey50};
                  &:last-child {
                    & i{
                      font-size:unset;
                    }

                  }
                }
              }
             }
          }
          & .cms-conditional-container{
            
             margin-left: -24px;
              & .grid-container > div{
                 display:flex;
                 align-items: center;
                 
                 padding-bottom:5px;
                 line-height: 25.2px;
                 letter-spacing: 0.04em;
                 gap:10px;
                  margin-bottom: 10px;
                    
                  .cms-image-container{
                   display:flex;
                   width: fit-content;
                   img{
                      width: 25px;
                   }
                 
              }
              a{
                font-family:${vars.fonts.oswald};
                 font-size: ${vars.fonts.fontSize16};
                 color: ${vars.colors.grey50};
                 margin:unset;

              }
            }
          }
        }
      `,
      breadcrumbCSS: `
        padding: 12px 24px 12px 0px;
        & a {
          display: flex;
          align-items: center;
          gap: 8px;
          &:hover, &:active, &:focus {
            text-decoration: none;
          }
          & .link-text {
            text-decoration: none;
            border-bottom: 1px solid ${vars.colors.primary8};
            font-size: ${vars.fonts.sizeH5};
            font-weight: bold;
          }
        }
      `,
    },
    Membership: {
      pageWrapper: `
      ${buildThemeWidget.Membership.pageWrapper}
      & .sidebar{
        display: none;
        ${vars.breakpoints.md} {
           display:flex;
        }
      }
      & .top-section{
          .dynamicComponents{
            >.widget-container{
             .cms-image-container{
                background: ${vars.colors.whiteWine};
                display:flex;
                justify-content:center;
                img{
                  padding:10px;
                  width:300px;
                }
              }
            }
            >.widget-cms-column{
              & .grid-container {
                    justify-content: space-between;
                    & .cms-image-container img{
                      width:88px;

                    }

              }
            }
          }
        }
        & .bottom-section{
          & .accordion-item{
             padding: 15px 0px;
            .accordion-title h3{
                font-size: ${vars.fonts.fontSize18};
            }
            .accordion-content h8{
              font-weight: normal;

            }
          }
        }
      
      `,
    },
    MembershipRebranding: {
      membershipStatus: `
      ${buildThemeWidget.MembershipRebranding.membershipStatus}
        border: 1px solid ${vars.colors.lwGrey400};
        padding: 24px;
        .re-join-note{
           margin-top: 12px;
        }
        .year-note {
          font-weight: ${vars.fonts.weightBold};
          font-family: ${vars.fonts.roboto};
          color: ${vars.colors.unltPopupBackground};
       
        }
        .add-unlimited-to-cart button{
           margin-top: 20px;
          background: ${vars.colors.unltPopupBackground};
          :hover{
           background-color: ${(vars.colors.bgColors as unknown as { [key: string]: string }).LWDarkBlue};
           color: ${vars.colors.white};
            
          }
        }
        .saving-instruction {
          font-family: ${vars.fonts.roboto};
          padding-top: 10px;
          font-weight: ${vars.fonts.weightNormal};
          font-size: ${vars.fonts.sizeLarge};
        }
        .saving-note {
          font-family: ${vars.fonts.roboto};
          font-weight: ${vars.fonts.weightNormal};
          font-size: ${vars.fonts.fontSize14};
          color: ${vars.colors.grey60};
          margin-top: 12px;
        }
        .price-section {
          display: flex;
          align-items: baseline;
        }
        .membership-status-title {
          font-family: ${vars.fonts.noto};
          font-weight: ${vars.fonts.weightMediumPlus};
        }
        .membership-subscription-title {
          font-family: ${vars.fonts.roboto};
          font-weight: 700;
          font-size: ${vars.fonts.sizeLarge};
          padding-top: 10px;
        }
        .enroll-info {
          margin-top: 12px;
          font-family: ${vars.fonts.roboto};
          font-weight: ${vars.fonts.weightBold};
          font-size: ${vars.fonts.sizeLarge};
          & .date {
            font-weight: ${vars.fonts.weightNormal};
            font-size: ${vars.fonts.sizeLarge};
            padding-right: 8px;
          }
        }
      `,
      layout: `
         ${buildThemeWidget.MembershipRebranding.layout}
         & .change-payment-card a{
           font-family: ${vars.fonts.roboto};
           text-decoration: underline;
           font-size: ${vars.fonts.fontSize14};
           }
       `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsAPACLWNZ, buildThemeMixinsAPACLWNZ, buildThemeWidgetStylesAPACLWNZ);

export default createTheme;
